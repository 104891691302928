.pager {
    background-color: #ffffff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: 0;
    padding: 16px 8px;

    &:global(.ant-table-pagination.ant-pagination) {
        margin: 0;
    }
}

