.title {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.subtitle {
    color: #404040;
    margin-bottom: 16px;
}

.container {
    border-radius: 8px;
    background: #141e2e;
}

.metricCol {
    cursor: pointer;

    &:nth-child(-n + 3) {
        .metricBox {
            padding: 34px 24px 17px 24px;
        }
    }
}
.metricBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    padding: 17px 24px 34px 24px;
}

.name {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 19px;
}

.data {
    font-size: 48px;
    font-weight: 700;
    color: #1890ff;
    line-height: 58px;
    margin-top: 8px;
}

.derivedData {
    font-weight: 500;
    color: #bfbfbf;
    margin-top: 4px;
    line-height: 17px;
}

