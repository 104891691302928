.title {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.content {
    color: #666666;
}

