@import "./PlainHtmlSummary.module.less";

.sectionTitle {
    font-size: 16px;
    font-weight: 500;
    color: #4fbd19;
}

.strategyTable {
    margin: 16px 0;
}

.strategyTitle {
    font-weight: 500;
    color: #000000;
}

.askBox {
    padding: 9px 0;
    padding-left: 22px;
    margin: 12px 0 12px -22px;
    border-radius: 4px;
    background: #ffecdb;
}

tr.opportunity {
    &:global(.ant-table-row) {
        background: #f2fced;
    }
}

tr.opportunityPercentage {
    &:global(.ant-table-row) {
        background: #e9fae0;
    }
}

.check {
    :global(.ant-checkbox) {
        :global(.ant-checkbox-inner) {
            border-color: #666666;
        }
    }

    :global(.ant-checkbox:hover) {
        :global(.ant-checkbox-inner) {
            border-color: #4fbd19;
        }
    }

    :global(.ant-checkbox-checked) {
        :global(.ant-checkbox-inner) {
            background: #4fbd19;
            border-color: #4fbd19;
        }
    }
}

