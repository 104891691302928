.summary {
    border-radius: 8px;
    background: #fafafa;
    border-bottom: 1px solid #d9d9d9;

    :global(.ant-collapse-item) {
        :global(.ant-collapse-header) {
            color: #404040;

            :global(.ant-collapse-header-text) {
                width: 100%;
            }

            .headerTitle {
                font-size: 16px;
                font-weight: 500;
                color: #1890ff;
            }

            .expandIcon {
                cursor: pointer;
                color: #1890ff;
                transition: all 0.3s;
                transform-origin: center;
            }
        }

        &:global(.ant-collapse-item-active) {
            :global(.ant-collapse-header) {
                color: #ffffff;
                background: #1890ff;
                border-radius: 8px 8px 0 0;

                .headerTitle,
                .edit {
                    color: #ffffff;
                }
            }

            .expandIcon {
                color: #ffffff;
                transform: translateY(-50%) rotate(180deg);
            }
        }

        :global(.ant-collapse-content) {
            background: #fafafa;
        }
    }
}

