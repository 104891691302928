.title {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.vendorBox {
    width: 150px;
}

