.ant-picker-calendar {
    .ant-picker-cell {
        .ant-picker-calendar-date {
            border-top: none;
            background: #fafafa;
            margin: 2px 2px;
            border-radius: 4px;

            .ant-picker-calendar-date-value {
                color: #000000;
                font-weight: 500;
            }
        }

        &.ant-picker-cell-selected {
            .ant-picker-calendar-date {
                background: #f4fcee;

                .ant-picker-calendar-date-value {
                    color: #000000;
                    font-weight: 500;
                }
            }
        }
    }
}
