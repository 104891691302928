.main {
    padding: 12px 16px 16px 16px;
    border-radius: 4px;
    background: #f6f7f9;
}

.title {
    font-weight: 500;
    color: #000000;
}

.tipIcon {
    color: #1890ff;
}

.data {
    margin-top: 14px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
}

