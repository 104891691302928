.main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
}

.chartContainer {
    background: #fafafa;
    border-bottom: 1px solid #d9d9d9;
    padding: 16px;
    border-radius: 8px;
}

.titleRow {
    margin-bottom: 16px;
}

.title {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.legendGreen,
.legendBlue {
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 12px;
    color: #666666;

    &::after {
        content: "";
        display: block;
        width: 14px;
        height: 0px;
        border-radius: 10px;
        border-top-width: 3px;
        border-top-style: solid;
    }
}

.legendGreen::after {
    border-color: #c1f2a9;
}

.legendBlue::after {
    border-color: #1890ff;
}

