.container {
    background: #fafafa;
    border-bottom: 1px solid #d9d9d9;
    padding: 16px;
    border-radius: 8px;
}

.title {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 16px;
}

