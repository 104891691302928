.title {
    margin-bottom: 8px;
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.content {
    margin-top: 8px;
    padding-left: 1rem;
    color: #666666;
    margin-bottom: 0;
}

.itemName {
    font-weight: 500;
}

