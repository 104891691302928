.maint {
    color: #000000;
}

.title {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
}

.tipIcon {
    color: #1890ff;
}

.newsContainer {
    border-radius: 8px;
    padding: 16px;
    background: #fafafa;
    border-bottom: 1px solid #d9d9d9;
}

.priceContainer {
    border-radius: 4px;
    background: #ffffff;
    padding: 8px 10px;
}

.percentageContainer {
    font-weight: 500;
}
.percentagePrefix {
    font-size: 16px;
}

.percentage {
    font-size: 20px;
}

.subtitle {
    font-size: 16px;
    font-weight: 500;
    margin: 16px 0;
}

.newsTitle {
    &:global(.ant-typography) {
        font-weight: 500;
        margin-bottom: 4px;
    }
}

.newsDate {
    font-size: 12px;
    color: #666666;
}

.loadMore {
    width: 100%;
    margin-top: 24px;
}

