.titleRow {
    margin: 16px 0 8px 0;
}

.title {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

