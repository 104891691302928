.container {
    background: #fafafa;
    border-radius: 8px;
    border-bottom: 1px solid #d9d9d9;
    overflow: hidden;
    color: #000000;
    font-weight: 500;
    margin-top: 24px;
}

.info {
    padding: 16px;
    display: flex;
    gap: 48px;
}

.nameWrapper {
    width: min-content;
}

.name {
    font-size: 20px;
    width: auto;
    white-space: nowrap;
    min-width: 140px;
}

.address {
    display: inline-block;
    width: 100%;
    font-size: 12px;
    line-height: 1;
}

.employee {
    font-size: 12px;
    color: #666666;
}

.employeeNumber {
    font-size: 14px;
    font-weight: 400;
    color: #52c41a;
}

.social {
    display: flex;
    gap: 16px;
    margin-left: 102px;
}

.pdfButton {
    flex: 1;
    display: flex;
    justify-content: end;
}

.priceBlock {
    font-weight: 500;
    color: #666666;
}

.price {
    font-size: 20px;
    white-space: nowrap;
}

.priceChange {
    font-size: 16px;
}

