.container {
    background: #fafafa;
    border-bottom: 1px solid #d9d9d9;
    padding: 16px;
    border-radius: 8px;
}

.titleRow {
    margin-bottom: 16px;
}

.title {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.iconButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.statementsTable {
    :global(tr.ant-table-row-level-1) {
        color: #666666;
        font-size: 12px;
        background-color: #ffffff;
    }
}

.expandedRow {
    color: #000000;
}

