.label {
    font-weight: 500;
    color: #000000;
}

.divider {
    margin: 8px 0;
    border-top-color: #bfbfbf;
}

.numerator,
.denominator {
    text-align: center;
}

